/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCatalogColumnCommand } from '../models/AddCatalogColumnCommand';
import type { AddUsersToCatalogCommand } from '../models/AddUsersToCatalogCommand';
import type { ApplyReferralLinkCommand } from '../models/ApplyReferralLinkCommand';
import type { ChangeCatalogRoleCommand } from '../models/ChangeCatalogRoleCommand';
import type { ChangeElementColumnCommand } from '../models/ChangeElementColumnCommand';
import type { ChangeElementOrderCommand } from '../models/ChangeElementOrderCommand';
import type { ClearReferralLinksOfCatalogCommand } from '../models/ClearReferralLinksOfCatalogCommand';
import type { CreateCatalogColumnCommand } from '../models/CreateCatalogColumnCommand';
import type { CreateCatalogCommand } from '../models/CreateCatalogCommand';
import type { CreateReferralLinkCommand } from '../models/CreateReferralLinkCommand';
import type { DeleteCatalogColumnCommand } from '../models/DeleteCatalogColumnCommand';
import type { Element } from '../models/Element';
import type { GetCatalogsUsersQuery } from '../models/GetCatalogsUsersQuery';
import type { ReferralLink } from '../models/ReferralLink';
import type { RemoveCatalogRoleCommand } from '../models/RemoveCatalogRoleCommand';
import type { SetCatalogCoverCommand } from '../models/SetCatalogCoverCommand';
import type { SetCatalogThemeCommand } from '../models/SetCatalogThemeCommand';
import type { ShiftCatalogColumnCommand } from '../models/ShiftCatalogColumnCommand';
import type { ShortUser } from '../models/ShortUser';
import type { UpdateCatalogColumnNameCommand } from '../models/UpdateCatalogColumnNameCommand';
import type { UpdateCatalogCommand } from '../models/UpdateCatalogCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CatalogService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogGet({
        token,
        id,
        getElements,
    }: {
        token?: string | null,
        id?: number,
        getElements?: boolean,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Catalog/Get',
            query: {
                'Token': token,
                'Id': id,
                'GetElements': getElements,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogCreate({
        requestBody,
    }: {
        requestBody: CreateCatalogCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/Create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogAddColumn({
        requestBody,
    }: {
        requestBody: AddCatalogColumnCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/AddColumn',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogCreateCatalogColumn({
        requestBody,
    }: {
        requestBody: CreateCatalogColumnCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/CreateCatalogColumn',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogUpdate({
        requestBody,
    }: {
        requestBody: UpdateCatalogCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/Update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Element
     * @throws ApiError
     */
    public catalogGetElementsOfList({
        token,
        id,
    }: {
        token: string,
        id: number,
    }): CancelablePromise<Array<Element>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Catalog/GetElementsOfList/{token}/{id}',
            path: {
                'token': token,
                'id': id,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogChangeElementOrder({
        requestBody,
    }: {
        requestBody: ChangeElementOrderCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/ChangeElementOrder',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogChangeElementStatus({
        requestBody,
    }: {
        requestBody: ChangeElementColumnCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/ChangeElementStatus',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogUpdateCatalogColumnName({
        requestBody,
    }: {
        requestBody: UpdateCatalogColumnNameCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/UpdateCatalogColumnName',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogShiftCatalogColumn({
        requestBody,
    }: {
        requestBody: ShiftCatalogColumnCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/ShiftCatalogColumn',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogDeleteCatalogColumn({
        requestBody,
    }: {
        requestBody: DeleteCatalogColumnCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/DeleteCatalogColumn',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns string
     * @throws ApiError
     */
    public catalogCreateReferralLink({
        requestBody,
    }: {
        requestBody: CreateReferralLinkCommand,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/CreateReferralLink',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ReferralLink
     * @throws ApiError
     */
    public catalogApplyReferralLink({
        requestBody,
    }: {
        requestBody: ApplyReferralLinkCommand,
    }): CancelablePromise<ReferralLink> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/ApplyReferralLink',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ReferralLink
     * @throws ApiError
     */
    public catalogGetReferralLinksOfCatalog({
        token,
        catalogId,
    }: {
        token?: string | null,
        catalogId?: number,
    }): CancelablePromise<Array<ReferralLink>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Catalog/GetReferralLinksOfCatalog',
            query: {
                'Token': token,
                'CatalogId': catalogId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogClearReferralLinksOfCatalog({
        requestBody,
    }: {
        requestBody: ClearReferralLinksOfCatalogCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/ClearReferralLinksOfCatalog',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ShortUser
     * @throws ApiError
     */
    public catalogGetCatalogUsers({
        token,
        catalogId,
        limit,
    }: {
        token?: string | null,
        catalogId?: number,
        limit?: number | null,
    }): CancelablePromise<Array<ShortUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Catalog/GetCatalogUsers',
            query: {
                'Token': token,
                'CatalogId': catalogId,
                'Limit': limit,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogGetCatalogsUsers({
        requestBody,
    }: {
        requestBody: GetCatalogsUsersQuery,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/GetCatalogsUsers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogRemoveCatalogRole({
        requestBody,
    }: {
        requestBody: RemoveCatalogRoleCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/RemoveCatalogRole',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogChangeCatalogRole({
        requestBody,
    }: {
        requestBody: ChangeCatalogRoleCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/ChangeCatalogRole',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogAddUsersToCatalog({
        requestBody,
    }: {
        requestBody: AddUsersToCatalogCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/AddUsersToCatalog',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogUploadCover({
        form,
    }: {
        form?: Array<any> | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Catalog/UploadCover',
            query: {
                'form': form,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogSetCatalogCover({
        requestBody,
    }: {
        requestBody: SetCatalogCoverCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/SetCatalogCover',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public catalogSetCatalogTheme({
        requestBody,
    }: {
        requestBody: SetCatalogThemeCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Catalog/SetCatalogTheme',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
