<script setup>
    import listHelper from "../listHelper";

    defineProps(['item', 'open', 'isFetching']);

    const getRouteLink = (item) => {
        if (item.isGroup) {
            console.log(item)
            return { name: 'Group', params: { id: item.groupId } }
        }
        if (item.goToSharedLists) {
            return { name: 'SharedLists'}
        }
        if (item.childCatalog) {
            return { name: 'List', params: { id: item.childCatalog.id }}
        }
        return { name: 'List', params: {id: item.id} }
    };
</script>

<template>
    <details :open="open">
        <summary class="flex items-center justify-between gap-2 py-1.5"><!-- чтобы отображать сворачивание вконце используем flex-row-reverse  -->
            <img v-if="isFetching" src="@/assets/icons/loader.svg" width="20" height="20" />
            <svg v-else-if="item.childrens" class="cursor-pointer fill-current" width="20" height="20" viewBox="0 0 20 20">
                <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/>
            </svg>
            <router-link class="flex items-center gap-2 font-medium" :to="getRouteLink(item)">
                <slot name="icon">
                    <span class="text-xl" v-if="item.emoji">
                        {{ item.emoji }}
                    </span>      
                </slot>
                {{ listHelper.getListName(item) }}
            </router-link>
            <slot name="end" v-bind="item"></slot>
        </summary>
        <div class="grid gap-1 ml-8" v-if="item.childrens?.length">
            <Tree v-for="child in item.childrens" :key="child.id" :item="child">
                <template #end>
                    <slot name="end" v-bind="child"></slot>
                </template>
            </Tree>
        </div>
    </details>
</template>

<style scoped>
    details {
        user-select: none;
    }
    details[open] > summary .cursor-pointer {
        transform: rotate(-90deg);
    }
    summary .cursor-pointer{
        transform: rotate(90deg);
    }

    summary::-webkit-details-marker {
        display: none;
    }
</style>