<template>
	<div>
		<v-menu
				open-on-hover
				:close-on-content-click="false"
				:nudge-width="200"
				offset-x
		>
			<template v-slot:activator="{ on, attrs }">
				<!--				<v-btn-->
				<!--								color="indigo"-->
				<!--								dark-->
				<!--								v-bind="attrs"-->
				<!--								v-on="on"-->
				<!--				>-->
				<!--					Menu as Popover-->
				<!--				</v-btn>-->
				<v-img
						:src="avatar"
						max-height="20"
						max-width="20"
						style="border-radius: 10px"
						v-on="on"
						v-bind="attrs"
				></v-img>
			</template>
			
			<v-card>
				<v-list>
					<v-list-item>
						<v-list-item-avatar>
							<img
									:src="avatar"
							>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>
								{{nickName}}
								<!--								{{this.isFriend ? '🤝' : ''}}-->
								<v-icon v-if="isFriendRequest" small>mdi-account-plus</v-icon>
								<v-icon v-else-if="isFriend && heAgree" small>mdi-account-multiple</v-icon>
								<v-icon v-else-if="isFriend" small>mdi-account-multiple-outline</v-icon>
								<span style="opacity: 0.5; font-size: 0.9em">
									{{statusOfFriend}}
								</span>
							</v-list-item-title>
							<v-list-item-subtitle>{{getRole()}}</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-progress-circular v-if="isPending"
							                     indeterminate
							                     color="grey darken-1"
							></v-progress-circular>
							<v-tooltip bottom v-else-if="isFriendRequest">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
											icon
											@click="addFriend"
											v-bind="attrs"
											v-on="on"
											elevation="1"
									>
										<v-icon>mdi-check</v-icon>
									</v-btn>
								</template>
								<span>Accept friend request</span>
							</v-tooltip>
							<v-tooltip bottom v-else-if="!isFriend">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
											icon
											@click="addFriend"
											v-bind="attrs"
											v-on="on"
											elevation="1"
									>
										<v-icon>mdi-account-plus</v-icon>
									</v-btn>
								</template>
								<span>Add to friends</span>
							</v-tooltip>
							<v-tooltip bottom v-else>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
											icon
											@click="deleteFriend"
											v-bind="attrs"
											v-on="on"
											elevation="1"
									>
										<v-icon>mdi-account-remove</v-icon>
									</v-btn>
								</template>
								<span>Remove from friends</span>
							</v-tooltip>
						</v-list-item-action>
					</v-list-item>
				</v-list>
				<v-divider></v-divider>
				<v-list>
					<v-list-item v-for="(li, i) in commonLists" :key="i" style="cursor: pointer" @click="goToList(li)">
						<v-list-item-title>{{li.emoji + ' ' + listHelper.getName(li)}}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
    import listHelper from "../../listHelper";
    import EventBus from "../../event-bus";

    export default {
        name: "UserAvatar",
        props: {
            item: {
                type: Object,
                required: true
            },
            isRequest: Boolean,
        },
        data() {
            return {
                avatar: 'https://thumbs.dreamstime.com/b/default-avatar-profile-trendy-style-social-media-user-icon-187599373.jpg',
                isDefault: true,
                listHelper,
                commonLists: [],
                isFriend: false,
                isFriendRequest: false,
                isPending: false,
                heAgree: false
            }
        },
        computed: {
            nickName() {
                return this.getUserName(this.item)
            },
            statusOfFriend() {
                if (this.isFriendRequest) {
                    return 'Friend request'
                } else if (!this.isFriend) {
                    return '';
                } else if (this.heAgree) {
                    return 'Friend'
                } else {
                    return 'Sent friend request'
                }
            }
        },
        mounted() {
            this.updateFriendStatus();
            this.reloadAvatar();
            EventBus.$on('REFRESH_AVATARS', (payload) => {
                if (this.isDefault) {
                    this.reloadAvatar();
                }
            });

            this.reloadAvatarsCatalogs();
            EventBus.$on('REFRESH_AVATARS_CATALOGS', (payload) => {
                this.reloadAvatarsCatalogs();
            });
            EventBus.$on('APPLY_FRIENDSHIP_TO_AVATAR', (id) => {
                if (id == this.item.id) {
                    this.applyFriendship(true)
                }
            });
            EventBus.$on('REJECT_FRIENDSHIP_REQUEST', (id) => {
                if (id == this.item.id) {
                    this.applyFriendship(false)
                }
            });

        },
        methods: {
            reloadAvatar() {
                if (this.item == null) return; //fixme
                const avatarFromLocalStorage = localStorage.getItem('avatar-' + this.item.external_id);
                if (avatarFromLocalStorage) {
                    this.isDefault = false;
                    this.avatar = `https://ten.lv/images/${avatarFromLocalStorage}/userpic.jpg`;
                    this.$forceUpdate();
                    return;
                }
                const avatar = this.$store.state.avatar.avatars[this.item.external_id];
                if (avatar == null) return;
                this.isDefault = false;
                this.avatar = `https://ten.lv/images/${avatar}/userpic.jpg`;
                this.$forceUpdate();
            },
            getRole() {
                if (this.item == null) { //fixme
                    return ''
                }
                switch (this.item.role) {
                    case 0:
                        return 'owner';
                    case 1:
                        return 'editor';
                    default :
                        return 'reader'
                }
            },
            goToList(list) {
                this.$router.push({name: 'List', params: {id: list.id}}).catch(() => {
                })
            },
            reloadAvatarsCatalogs() {
                this.commonLists.length = 0;
                this.$forceUpdate();
                const sharedLists = this.$store.state.home.sharedLists;
                sharedLists.forEach(s => {
                    if (s.users == null) {
                        return;
                    }
                    if (this.item == null) return; //fixme
                    const currentUser = s.users.find(u => u.external_id === this.item.external_id);
                    if (currentUser != null) {
                        this.commonLists.push(s);
                    }
                });
                this.$forceUpdate()
            },
            addFriend() {
                if (this.isPending) return false;
                this.isPending = true;
                this.$store.dispatch('home/addFriend', this.item.id)
                    .then(() => {
                        this.applyFriendship();
                        EventBus.$emit('APPLY_FRIENDSHIP_TO_NOTIFICATION', this.item.id);
                    })
                    .catch((error) => {
                        this.isPending = false;
                        console.log(error)
                    })
            },
            deleteFriend() {
                if (this.isPending) return false;
                this.isPending = true;
                this.$store.dispatch('home/deleteFriend', this.item.id)
                    .then(() => {
                        this.isFriend = false;
                        this.isFriendRequest = false;
                        this.isPending = false;
                        this.$store.dispatch('notify/setNotify', {
                            type: 'error',
                            text: `${this.nickName} removed from friends`
                        });
                        this.$store.dispatch('home/loadFriends')
                            .then(() => {
                                this.$store.dispatch('avatar/tryToUpload');
                            })
                    })
                    .catch((error) => {
                        this.isPending = false;
                        console.log(error)
                    })
            },
            updateFriendStatus() {
                if (this.item == null) return; //fixme
                let friend = this.$store.state.home.friends.find(x => x.id === this.item.id);
                if (friend != null && !friend.i_agree) {
                    this.isFriendRequest = true;
                }
                if (friend != null && !this.isFriendRequest) {
                    this.isFriend = true;
                }
                if (this.isFriend) {
                    this.heAgree = friend.he_agree;
                }
            },
            applyFriendship(applied) {
                this.isFriend = applied;
                this.isFriendRequest = false;
                this.isPending = false;
                if (applied) {
                    this.$store.dispatch('notify/setNotify', {
                        type: 'error',
                        text: `${this.nickName} added to friends`
                    });
                    this.$store.dispatch('home/loadFriends')
                        .then(() => {
                            this.$store.dispatch('avatar/tryToUpload');
                        })
                } else {
                    this.$store.dispatch('home/loadFriends')
                        .then(() => {
                            this.$store.dispatch('avatar/tryToUpload');
                        })
                }
            }
        }
    }
</script>

<style scoped>

</style>