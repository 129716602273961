import api from "@/api/api"

import { ref, computed } from 'vue';
import store from '../store/index';
import EventBus from "../event-bus";

export const useGroups = () => {
    const { token } = store.getters;
    const groups = ref([]);
    const visibleGroups = computed(() => groups.value.filter((g:any) => !g.hidden));

    const changeGroupSettings = async ({ context, groupId, visible }) => {
        await api.user.userChangeGroupSettings({ requestBody: { token, context, groupId, visible } });

        EventBus.$emit('RELOAD_GROUPS');
    }
    const loadGroups  = () => {
        api.user.userGetGroupChatsOfUser({ token }).then((data) => {

            data.forEach(async (group) => {
                group.name = group.title;
                group.emoji = '👥';
                group.isGroup = true;
    
                group.catalogs.forEach(c => {
                    c.isFolder = true;
                });
    
                group.childrens = group.catalogs;
            });
    
            groups.value = data;
        });
        
    }

    EventBus.$on('RELOAD_GROUPS', loadGroups);

    loadGroups();

    return {
        groups,
        visibleGroups,
        changeGroupSettings
    }
}