import store from '../store/index';
import api from '../api/api';
import { ref } from 'vue';

const navigation = ref({});

export const useNavigation = () => {
    const { user } = store.state;
    const { userName, externalId } = user;
    const { token } = store.getters;
    const isFetching = ref(true);

    Promise.all([
        api.user.userGetListsOfUser({ token, userName, externalId }),
        api.user.userGetSharedCatalogsOfUser({ token, externalId }).then(data => {
            
            data.forEach(async (list:any) =>  {
                if (list.id != -11) {
                    list.isFolder = true;
                } else {
                    list.elements = [];
                }
                // @ts-ignore
                list.uid = crypto.randomUUID();
            });

            return data;
        })
    ]).then(([user, shared]) => {
        isFetching.value = false;
        navigation.value = { user, shared };
    });

    return {
        isFetching,
        navigation,
    }
}
