<template>
    <v-app id="app" class="min-h-screen flex flex-col" :style="`background: linear-gradient(45deg, ${$tl_background_color()}, color-mix(in srgb, ${$tl_background_color()}, white 20%))`">
        <div v-if="$router.history.current.name != 'Mindmap'" class="inset-0 fixed" :style="{
		     'background-image': `url('${cover}')`,
		     'background-size': 'cover',
		     'background-position-x': 'center',
		     'background-position-y': 'center',
             'background-attachment': 'fixed',
             'filter': 'blur(4px)',
             'transform': 'scale(1.1)',
             'opacity': cover ? 0.99 : 0,
             'transition': 'opacity .6s ease'
		     }"></div>
        <app-spinner v-if="showSpinner" />
        <transition name="fade" mode="out-in">
            <v-alert v-if="notify.show" color="#787777" dark
                style="position: fixed; bottom: 20px; left: 20px; z-index: 5000">
                <v-row v-if="notify.link" align="center" no-gutters>
                    <v-col class="grow">
                        {{ notify.text }}
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="shrink">
                        <v-btn @click="goToLink(notify.link)" outlined>
                            Show
                        </v-btn>
                    </v-col>
                </v-row>
                <template v-else>{{ notify.text }}</template>
            </v-alert>
        </transition>
        <div class="flex">
            <nav class="w-72 overflow-auto sticky top-0 max-h-screen p-4 inline-flex flex-col transition-all" :class="isNavOpen ? 'ml-0' : '-ml-72'" style="background: rgba(255,255,255, .9)">
            <div class="inline-flex items-center justify-between flex-wrap" v-if="groups.length">
                Groups <span class="text-xs mr-auto ml-2 text-yellow-600">hasHidden</span>
                <template v-if="groups.length != visibleGroups.length">
                    <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item @click="showHiddenGroups = !showHiddenGroups">
                                <template v-if="!showHiddenGroups">
                                    <v-list-item-icon style="margin-right: 10px">
                                        <v-icon :color="'grey darken-2'">mdi-eye</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>SHOW HIDDEN GROUPS</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-icon style="margin-right: 10px">
                                        <v-icon :color="'grey darken-2'">mdi-eye-off</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>HIDE HIDDEN GROUPS</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-menu> 
                </template>
            </div>
                <Tree :item="item" v-for="item in showHiddenGroups ? groups : visibleGroups" :key="item.id">
                    <template #icon>
                        <i aria-hidden="true" class="v-icon notranslate mdi mdi-account-multiple-outline theme--light"></i>
                    </template>
                    <template #end="group">
                        <v-menu offset-y :close-on-content-click="true" v-if="group.groupId == item.groupId">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" style="float: right;">
                                    <v-icon>mdi mdi-account-cog-outline</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <template v-if="group.globalSettings">
                                    <v-list-item v-if="group.role === 'creator'" @click="() => changeGroupSettings({ context: 'administrator', groupId: item.groupId, visible: !group.globalSettings.visible_for_administrator })">
                                        <template v-if="!group.globalSettings.visible_for_administrator">
                                            <v-list-item-icon style="margin-right: 10px">
                                                <v-icon :color="'grey darken-2'">mdi-eye</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>SHOW FOR ADMINISTRATORS</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-icon style="margin-right: 10px">
                                                <v-icon :color="'grey darken-2'">mdi-eye-off</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>HIDE FOR ADMINISTRATORS</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-list-item @click="() => changeGroupSettings({ context: 'member', groupId: item.groupId, visible: !group.globalSettings.visible_for_member })">
                                        <template v-if="!group.globalSettings.visible_for_member">
                                            <v-list-item-icon style="margin-right: 10px">
                                                <v-icon :color="'grey darken-2'">mdi-eye</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>SHOW FOR MEMBERS</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-icon style="margin-right: 10px">
                                                <v-icon :color="'grey darken-2'">mdi-eye-off</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>HIDE FOR MEMBERS</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                </template>
                                <v-list-item v-if="group.personalSettings" @click="() => changeGroupSettings({ context: 'personal', groupId: item.groupId, visible: !group.personalSettings.visible })">
                                    <template v-if="!group.personalSettings.visible">
                                        <v-list-item-icon style="margin-right: 10px">
                                            <v-icon :color="'grey darken-2'">mdi-eye</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>SHOW FOR ME</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-icon style="margin-right: 10px">
                                            <v-icon :color="'grey darken-2'">mdi-eye-off</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>HIDE FOR ME</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </Tree>
                <Tree :item="{ childrens: navigation.shared, goToSharedLists: true, name: _shared(), emoji: '👥' }" :open="true" :isFetching="isFetching">
                    <template #end="endProps">
                        <UserAvatarList class="inline-flex ml-auto" v-if="endProps.users" :avatars="endProps.users" :limit="2"/>
                    </template>
                </Tree>
                <hr class="-mx-4 my-2">
                <Tree :item="item" v-for="item in navigation.user" :key="item.id" :isFetching="isFetching" />

            <!-- <template v-slot:append v-if="isMobile">
                <div class="pa-2">
                    <v-menu :offset-y="true">
                        <template v-slot:activator="{ on: menu }">
                            <v-btn block depressed v-on="{ ...menu }">
                                <v-avatar style="cursor: pointer;" size="26px">
                                    <img v-if="imgUrl" alt="Avatar" :src="imgUrl">
                                    <v-icon v-else>person
                                    </v-icon>
                                </v-avatar>&nbsp;&nbsp;
                                {{ userName }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="showUserLanguageForm = true">
                                <v-list-item-title>
                                    <v-icon>mdi-web</v-icon>
                                    {{ $t('language') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title @click="onLogout">
                                    <v-icon>mdi-exit-to-app</v-icon>
                                    {{ $t('log-out') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template> -->
        </nav>
        <div class="flex-1 overflow-auto flex flex-col">
            <header class="flex sticky top-0 z-20 p-4 left-0 gap-2 flex-grow-0" :class="{'-mb-20': $router.history.current.name == 'Mindmap'}">
                <v-app-bar-nav-icon class="bg-white rounded-full" @click.stop="() => isNavOpen = !isNavOpen" />
                    <template v-if="$router.history.current.name != 'Home'">
                    <router-link :to="{ name: 'Home' }" style="text-decoration: none; color: inherit">
                        <v-btn fab small depressed>
                            <v-icon>mdi-home</v-icon>
                        </v-btn>
                    </router-link>
                    <v-btn  fab small depressed @click="() => $router.go(-1)">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-toolbar-title class="inline-flex items-center bg-white rounded-full py-1 pl-2 pr-4 text-lg">
                        <router-view name="title" />
                    </v-toolbar-title>
                </template>
                <div class="ml-auto"></div>
                <v-btn v-if="showMusicButton" :color="activeMusicButton ? 'primary' : ''"
                    @click="activeMusicButton = !activeMusicButton" large icon rounded>
                    <v-icon>mdi-music-box</v-icon>
                </v-btn>
                <Notifications />
                <!-- <router-view name="viewSwitcher" /> -->
                <v-menu :offset-y="true" v-if="!isMobile">
                    <template v-slot:activator="{ on: menu }">
                        <v-avatar style="cursor: pointer; margin-left: 20px" size="36px" v-on="{ ...menu }">
                            <img v-if="imgUrl" alt="Avatar" :src="imgUrl">
                            <v-icon v-else>person
                            </v-icon>
                        </v-avatar>
                    </template>
                    <v-list>
                        <LanguageButton @click="showUserLanguageForm = true" />
                        <v-list-item @click="showMainMenuViewForm = true">
                            <v-list-item-title>
                                <v-icon>{{ currentViewOption.icon }}</v-icon>
                                {{ $t('menu-view') }}
                            </v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item @click="showUserLanguageForm = true">
                            <v-list-item-title>
                                <v-icon>mdi-web</v-icon>
                                {{ $t('language') }}
                            </v-list-item-title>
                        </v-list-item> -->
                        <v-list-item>
                            <v-list-item-title @click="onLogout">
                                <v-icon>mdi-exit-to-app</v-icon>
                                {{ $t('log-out') }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </header>

            <router-view name="main" :style="{ 'margin-top': showPlayer && isMobile ? '60px' : '' }" />
            <router-view name="usersToolbar" />
        </div>
    </div>
        <component v-if="showForm" :is="formComponent" />
        <CreateListForm v-if="showCreateListForm" @close="showCreateListForm = false" />
        <CreateColumnForm v-if="showCreateColumnForm" :catalogId="catalogId" @close="showCreateColumnForm = false" />

        <Player v-show="showPlayer" :style="{
            right: isMobile ? '0px' : '200px',
            top: isMobile ? '55px' : '',
        }" style="position: fixed; top: 0; z-index: 5" />
        <UserLanguageForm v-if="showUserLanguageForm" @close="showUserLanguageForm = false" />
        <MainMenuView v-if="showMainMenuViewForm" @close="showMainMenuViewForm = false" />
    </v-app>
</template>

<script setup>
import { ref } from 'vue';
import { useNavigation } from '@/composables/useNavigation';
import { useGroups } from '@/composables/useGroups';

const { navigation, isFetching } = useNavigation();
const { groups, visibleGroups, changeGroupSettings } = useGroups();
const showHiddenGroups = ref(false);
const isNavOpen = ref(false);

EventBus.$on('ROUTER_UPDATE', () => {
    isNavOpen.value = false;
});

</script>
<script>
import { mapState, mapGetters } from 'vuex'
import Tree from '@/components/Tree';
import UserAvatarList from "./components/users-toolbar/UserAvatarList";
import ElementForm from "./components/form/ElementForm";
import AttachImageForm from "./components/form/AttachImageForm";
import AddToFriendsForm from "./components/form/AddToFriendsForm";
import CreateListForm from "./components/form/CreateListForm";
import CreateColumnForm from "./components/form/CreateColumnForm";
import Player from "./components/player/Player";
import UsersOfCatalog from "./components/users-toolbar/UsersOfCatalog";
import UsersOfCatalogMobile from "./components/users-toolbar/UsersOfCatalogMobile";
import Notifications from "./components/Notifications";
import UploadCatalogCoverForm from "./components/form/UploadCatalogCoverForm";
import AppSpinner from "./components/AppSpinner";
import EventBus from "./event-bus";
import api from "./api/api";
import configs from "./config";

export default {
    name: 'app',
    components: {
        AppSpinner,
        ElementForm,
        AddToFriendsForm,
        AttachImageForm,
        CreateListForm,
        CreateColumnForm,
        Player,
        UsersOfCatalog,
        UsersOfCatalogMobile,
        Notifications,
        UploadCatalogCoverForm,
        UserLanguageForm: () => import(/* webpackChunkName: "UserLanguageForm" */ "./components/form/UserLanguageForm"),
        LanguageButton: () => import(/* webpackChunkName: "LanguageButton" */ "./components/LanguageButton"),
        MainMenuView: () => import(/* webpackChunkName: "LanguageButton" */ "./components/form/MainMenuView"),
    },
    data() {
        return {
            cover: null,
            dialog: false,
            drawer: false,
            drawerScrollbar: false,
            alert: false,
            activeMusicButton: false,
            showCreateListForm: false,
            showCreateColumnForm: false,
            catalogId: null,
            columnId: null,
            showUserLanguageForm: false,
            showMainMenuViewForm: false
        }
    },
    computed: {
        auth() {
            return this.$store.getters.isAuthenticated
        },
        login() {
            return this.$store.getters.login
        },
        showSharedLists() {
            return this.$store.state.home.showSharedLists
        },
        showPlayer() {
            return this.$store.state.playlist.showPlayer
        },
        showMusicButton() {
            return this.$store.state.playlist.showMusicButton
        },
        showForm() {
            return this.$store.state.form.show
        },
        formMode() {
            return this.$store.state.form.mode
        },
        imageUrl() {
            return this.$store.state.user.imageUrl
        },
        formComponent() {
            if (this.formMode === 'upload-catalog-cover') {
                return 'UploadCatalogCoverForm';
            }
            if (this.formMode === 'edit-image') {
                return 'AttachImageForm';
            }
            if (this.formMode === 'add-to-friends') {
                return 'AddToFriendsForm';
            }
            return 'ElementForm';
        },
        isMobile() {
            return false; // this.$store.state.flags.isMobile
        },
        imgUrl() {
            if (this.imageUrl == null) return 'https://thumbs.dreamstime.com/b/default-avatar-profile-trendy-style-social-media-user-icon-187599373.jpg';
            return configs.avatarUrl + this.imageUrl;
        },
        ...mapState({
            notify: state => state.notify.notify,
            theme: state => state.theme.theme
        }),
        ...mapGetters({
            currentisFetching: 'playlist/currentisFetching'
        }),
        userName() {
            if (this.$store.state.user.userName) {
                return this.$store.state.user.userName;
            }
            return this.$store.state.user.firstName;
        },
        showSpinner() {
            return this.$store.state.home.showSpinner
        },
        possibleViewOptions() {
            if (this.isMobile) {
                return this.$store.getters.homeViewOptions.filter(x => x.isMobile);
            } else {
                return this.$store.getters.homeViewOptions
            }
        },
        currentViewOption() {
            return this.possibleViewOptions.find(i => i.value === this.$store.state.options.homeView);
        },
    },
    watch: {
        activeMusicButton() {
            this.$store.dispatch('playlist/setShowPlayer', this.activeMusicButton)
        },
    },

    async created() {
        if (localStorage.getItem("aplayer-setting")) {
            this.$store.dispatch('playlist/setShowMusicButton', true)
        }
        this.$store.dispatch('setUserImage');
        if (!this.auth) return;
        setTimeout(() => {
            this.$store.dispatch('home/loadNotifications')
                .catch(error => {
                    if (error?.status === 401) {
                        this.$store.dispatch('logout');
                    }
                });
        }, 1000);
    },
    created() {
        this.$router.afterEach(() => {
            EventBus.$emit('ROUTER_UPDATE');
        });
    },
    mounted() {
        EventBus.$on('SET_COVER', (cover) => {
            this.cover = cover;
        });
        EventBus.$on('CREATE_LIST_FORM_SHOW', (payload) => {
            this.showCreateListForm = true;
            if (payload) {
                this.setCatalogAndColumn(payload.catalogId, payload.columnId);
            } else {
                this.setCatalogAndColumn(null, null);
            }
        });
        EventBus.$on('CREATE_COLUMN_FORM_SHOW', (payload) => {
            this.showCreateColumnForm = true;
            this.catalogId = payload;
            this.columnId = null;
        });
        EventBus.$on('CREATE_LIST', (name) => {
            api.createListCommand(name, this.catalogId, this.columnId)
                .then(() => {
                    if (this.catalogId) {
                        this.$store.dispatch('list/loadList', this.catalogId);
                    } else {
                        this.$store.dispatch('home/loadTopLevelElements')
                            .then(() => {
                                this.$forceUpdate()
                            })
                            .then(() => {
                                this.$store.dispatch('home/loadUsersOfLists')
                                    .then(() => {
                                        this.$forceUpdate()
                                    })
                            });
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert("Произошла ошибка")
                })
                .finally(() => {
                    this.showCreateListForm = false;
                })
        });
        EventBus.$on('CREATE_COLUMN', (name) => {
            api.addListColumnCommand(name, this.catalogId)
                .then(() => {
                    if (this.catalogId) {
                        this.$store.dispatch('list/loadList', this.catalogId);
                    } else {
                        this.$store.dispatch('home/loadTopLevelElements')
                            .then(() => {
                                this.$forceUpdate()
                            })
                            .then(() => {
                                this.$store.dispatch('home/loadUsersOfLists')
                                    .then(() => {
                                        this.$forceUpdate()
                                    })
                            });
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert("Произошла ошибка")
                })
                .finally(() => {
                    this.showCreateColumnForm = false;
                })
        });
        this.$store.dispatch('home/loadFriends')
            .then(() => {
                this.$store.dispatch('avatar/tryToUpload');
            })
            .catch(error => {
            });
    },
    methods: {
        onLogout() {
            this.$store.dispatch('logout');
        },
        toggleShowSharedLists() {
            this.$store.dispatch('home/setShowSharedLists', !this.showSharedLists);
        },
        setCatalogAndColumn(catalogId, columnId) {
            this.catalogId = catalogId;
            this.columnId = columnId;
        },
        toggleTheme() {
            this.$store.dispatch('theme/toggle');
        },
        goToLink(link) {
            window.location.href = link;
        }
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */
    {
    opacity: 0;
}

.drawer_content {
    height: 100%;
}
</style>
