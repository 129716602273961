/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { CatalogService } from './services/CatalogService';
import { ElementService } from './services/ElementService';
import { FileService } from './services/FileService';
import { MindMapService } from './services/MindMapService';
import { TestService } from './services/TestService';
import { UserService } from './services/UserService';
import { ValuesService } from './services/ValuesService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class Client {
    public readonly catalog: CatalogService;
    public readonly element: ElementService;
    public readonly file: FileService;
    public readonly mindMap: MindMapService;
    public readonly test: TestService;
    public readonly user: UserService;
    public readonly values: ValuesService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://api.ten.lv',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.catalog = new CatalogService(this.request);
        this.element = new ElementService(this.request);
        this.file = new FileService(this.request);
        this.mindMap = new MindMapService(this.request);
        this.test = new TestService(this.request);
        this.user = new UserService(this.request);
        this.values = new ValuesService(this.request);
    }
}

