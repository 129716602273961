/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddElementCommand } from '../models/AddElementCommand';
import type { AddElementToCalendarCommand } from '../models/AddElementToCalendarCommand';
import type { ClearColorOfElementCommand } from '../models/ClearColorOfElementCommand';
import type { Element } from '../models/Element';
import type { ElementColor } from '../models/ElementColor';
import type { MoveElementsToCatalogCommand } from '../models/MoveElementsToCatalogCommand';
import type { SetColorOfElementCommand } from '../models/SetColorOfElementCommand';
import type { UpdateElementCommand } from '../models/UpdateElementCommand';
import type { UpdateElementTextCommand } from '../models/UpdateElementTextCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ElementService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns Element
     * @throws ApiError
     */
    public elementGet({
        token,
        id,
    }: {
        token: string,
        id: number,
    }): CancelablePromise<Element> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Element/Get/{token}/{id}',
            path: {
                'token': token,
                'id': id,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public elementAdd({
        requestBody,
    }: {
        requestBody: AddElementCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Element/Add',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public elementUpdate({
        requestBody,
    }: {
        requestBody: UpdateElementCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Element/Update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public elementUpdateText({
        requestBody,
    }: {
        requestBody: UpdateElementTextCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Element/UpdateText',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns string
     * @throws ApiError
     */
    public elementGetAudio({
        token,
        id,
    }: {
        token: string,
        id: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Element/GetAudio/{token}/{id}',
            path: {
                'token': token,
                'id': id,
            },
        });
    }
    /**
     * @returns string
     * @throws ApiError
     */
    public elementGetImage({
        token,
        id,
    }: {
        token: string,
        id: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Element/GetImage/{token}/{id}',
            path: {
                'token': token,
                'id': id,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public elementGetDocument({
        token,
        fileId,
        fileName,
    }: {
        token?: string | null,
        fileId?: string | null,
        fileName?: string | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Element/GetDocument',
            query: {
                'token': token,
                'fileId': fileId,
                'fileName': fileName,
            },
        });
    }
    /**
     * @returns string
     * @throws ApiError
     */
    public elementDelete({
        token,
        id,
    }: {
        token: string,
        id: number,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Element/Delete/{token}/{id}',
            path: {
                'token': token,
                'id': id,
            },
        });
    }
    /**
     * @returns ElementColor
     * @throws ApiError
     */
    public elementGetElementColors({
        token,
    }: {
        token: string,
    }): CancelablePromise<Array<ElementColor>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Element/GetElementColors/{token}',
            path: {
                'token': token,
            },
        });
    }
    /**
     * @returns Element
     * @throws ApiError
     */
    public elementSetColorOfElementCommand({
        requestBody,
    }: {
        requestBody: SetColorOfElementCommand,
    }): CancelablePromise<Element> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Element/SetColorOfElementCommand',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Element
     * @throws ApiError
     */
    public elementClearColorOfElementCommand({
        requestBody,
    }: {
        requestBody: ClearColorOfElementCommand,
    }): CancelablePromise<Element> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Element/ClearColorOfElementCommand',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public elementMoveElementsToCatalog({
        requestBody,
    }: {
        requestBody: MoveElementsToCatalogCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Element/MoveElementsToCatalog',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public elementAddToCalendar({
        requestBody,
    }: {
        requestBody: AddElementToCalendarCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Element/AddToCalendar',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public elementUploadImage({
        form,
    }: {
        form?: Array<any> | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Element/UploadImage',
            query: {
                'form': form,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public elementUploadDocument({
        form,
    }: {
        form?: Array<any> | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Element/UploadDocument',
            query: {
                'form': form,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public elementGetFolder({
        elementId,
    }: {
        elementId?: number,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Element/GetFolder',
            query: {
                'elementId': elementId,
            },
        });
    }
}
