/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddFriendOfUserCommand } from '../models/AddFriendOfUserCommand';
import type { ApplyAddToFriendsLinkCommand } from '../models/ApplyAddToFriendsLinkCommand';
import type { AuthQuery } from '../models/AuthQuery';
import type { AuthUserInfo } from '../models/AuthUserInfo';
import type { Catalog } from '../models/Catalog';
import type { ChangeCatalogOrderCommand } from '../models/ChangeCatalogOrderCommand';
import type { ChangeGroupSettingsCommand } from '../models/ChangeGroupSettingsCommand';
import type { ChangeUserLanguageCommand } from '../models/ChangeUserLanguageCommand';
import type { Friend } from '../models/Friend';
import type { MarkNotificationAsReadCommand } from '../models/MarkNotificationAsReadCommand';
import type { ShortUser } from '../models/ShortUser';
import type { TempTokenAuth } from '../models/TempTokenAuth';
import type { UploadUserImageCommand } from '../models/UploadUserImageCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns AuthUserInfo
     * @throws ApiError
     */
    public userAuth({
        requestBody,
    }: {
        requestBody: AuthQuery,
    }): CancelablePromise<AuthUserInfo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/User/Auth',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns AuthUserInfo
     * @throws ApiError
     */
    public userTempTokenAuth({
        requestBody,
    }: {
        requestBody: TempTokenAuth,
    }): CancelablePromise<AuthUserInfo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/User/TempTokenAuth',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns AuthUserInfo
     * @throws ApiError
     */
    public userAuthGoogle({
        email,
        token,
    }: {
        email?: string | null,
        token?: string | null,
    }): CancelablePromise<AuthUserInfo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/AuthGoogle',
            query: {
                'Email': email,
                'Token': token,
            },
        });
    }
    /**
     * @returns string
     * @throws ApiError
     */
    public userUploadUserImage({
        requestBody,
    }: {
        requestBody: UploadUserImageCommand,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/User/UploadUserImage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Catalog
     * @throws ApiError
     */
    public userGetListsOfUser({
        token,
        userName,
        externalId,
    }: {
        token?: string | null,
        userName?: string | null,
        externalId?: string | null,
    }): CancelablePromise<Array<Catalog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetListsOfUser',
            query: {
                'Token': token,
                'UserName': userName,
                'ExternalId': externalId,
            },
        });
    }
    /**
     * @returns Catalog
     * @throws ApiError
     */
    public userChangeCatalogOrder({
        requestBody,
    }: {
        requestBody: ChangeCatalogOrderCommand,
    }): CancelablePromise<Array<Catalog>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/User/ChangeCatalogOrder',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Catalog
     * @throws ApiError
     */
    public userGetSharedCatalogsOfUser({
        token,
        externalId,
    }: {
        token: string,
        externalId?: string | null,
    }): CancelablePromise<Array<Catalog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetSharedCatalogsOfUser/{token}',
            path: {
                'token': token,
            },
            query: {
                'externalId': externalId,
            },
        });
    }
    /**
     * @returns ShortUser
     * @throws ApiError
     */
    public userGetUserFriends({
        token,
    }: {
        token?: string | null,
    }): CancelablePromise<Array<ShortUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetUserFriends',
            query: {
                'Token': token,
            },
        });
    }
    /**
     * @returns Friend
     * @throws ApiError
     */
    public userGetFriendsOfUser({
        token,
    }: {
        token?: string | null,
    }): CancelablePromise<Array<Friend>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetFriendsOfUser',
            query: {
                'Token': token,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userAddFriendOfUser({
        requestBody,
    }: {
        requestBody: AddFriendOfUserCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/User/AddFriendOfUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userDeleteFriendOfUser({
        token,
        userId,
    }: {
        token?: string | null,
        userId?: number,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/User/DeleteFriendOfUser',
            query: {
                'Token': token,
                'UserId': userId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userGetUserNotifications({
        token,
        skip,
        take,
    }: {
        token?: string | null,
        skip?: number | null,
        take?: number | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetUserNotifications',
            query: {
                'Token': token,
                'Skip': skip,
                'Take': take,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userGetUnreadNotificationCountOfUser({
        token,
    }: {
        token?: string | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetUnreadNotificationCountOfUser',
            query: {
                'Token': token,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userMarkNotificationAsRead({
        requestBody,
    }: {
        requestBody: MarkNotificationAsReadCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/User/MarkNotificationAsRead',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userGetLinkForAddingToFriends({
        token,
    }: {
        token?: string | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetLinkForAddingToFriends',
            query: {
                'Token': token,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userApplyAddToFriendsLink({
        requestBody,
    }: {
        requestBody: ApplyAddToFriendsLinkCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/User/ApplyAddToFriendsLink',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userGetCalendarElements({
        token,
        catalogId,
    }: {
        token?: string | null,
        catalogId?: number,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetCalendarElements',
            query: {
                'Token': token,
                'CatalogId': catalogId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userGetGroupChatsOfUser({
        token,
        showIsPossible,
    }: {
        token?: string | null,
        showIsPossible?: boolean,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetGroupChatsOfUser',
            query: {
                'Token': token,
                'ShowIsPossible': showIsPossible,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userGetGroup({
        token,
        groupId,
        showIsPossible,
    }: {
        token?: string | null,
        groupId?: number,
        showIsPossible?: boolean,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetGroup',
            query: {
                'Token': token,
                'GroupId': groupId,
                'ShowIsPossible': showIsPossible,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userChangeGroupSettings({
        requestBody,
    }: {
        requestBody: ChangeGroupSettingsCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/User/ChangeGroupSettings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userGetUserContactsOfUser({
        token,
        contextCatalogId,
    }: {
        token?: string | null,
        contextCatalogId?: number | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User/GetUserContactsOfUser',
            query: {
                'Token': token,
                'ContextCatalogId': contextCatalogId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public userChangeUserLanguage({
        requestBody,
    }: {
        requestBody: ChangeUserLanguageCommand,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/User/ChangeUserLanguage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
