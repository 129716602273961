/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppendMindMapNodeRequest } from '../models/AppendMindMapNodeRequest';
import type { CopyMindMapNodeRequest } from '../models/CopyMindMapNodeRequest';
import type { CutMindMapNodeRequest } from '../models/CutMindMapNodeRequest';
import type { SetMindMapNodeColorRequest } from '../models/SetMindMapNodeColorRequest';
import type { UpdateMindMapNodeRequest } from '../models/UpdateMindMapNodeRequest';
import type { ZoomMindMapRequest } from '../models/ZoomMindMapRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MindMapService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapGet({
        id,
        token,
    }: {
        id: number,
        token?: string | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/mindmap/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapPost({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: UpdateMindMapNodeRequest,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/mindmap/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapGetHistory({
        id,
        token,
    }: {
        id: number,
        token?: string | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/mindmap/{id}/history',
            path: {
                'id': id,
            },
            query: {
                'token': token,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapGetMindMapNode({
        elementId,
    }: {
        elementId: number,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/mindmap/node/{elementId}',
            path: {
                'elementId': elementId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapAppend({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: AppendMindMapNodeRequest,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/mindmap/{id}/append',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapDelete({
        id,
        token,
        nodeId,
    }: {
        id: number,
        token?: string | null,
        nodeId?: string | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/mindmap/{id}/delete',
            path: {
                'id': id,
            },
            query: {
                'Token': token,
                'NodeId': nodeId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapColor({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: SetMindMapNodeColorRequest,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/mindmap/{id}/color',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapGetViewBox({
        id,
        token,
    }: {
        id: number,
        token?: string | null,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/mindmap/{id}/view-box',
            path: {
                'id': id,
            },
            query: {
                'token': token,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapZoom({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: ZoomMindMapRequest,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/mindmap/{id}/view-box',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapPatch({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: CutMindMapNodeRequest,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/mindmap/{id}/cut',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public mindMapPatch2({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: CopyMindMapNodeRequest,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/mindmap/{id}/copy',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
