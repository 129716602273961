/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DownloadDocumentQuery } from '../models/DownloadDocumentQuery';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FileService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns binary
     * @throws ApiError
     */
    public fileDownloadDocument({
        requestBody,
    }: {
        requestBody: DownloadDocumentQuery,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/File/DownloadDocument',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
