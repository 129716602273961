import api from "../api/api";
import Track from "../models/Track";
import EventBus from "../event-bus";

// const jsmediatags = require("jsmediatags");

const initialState = {
    showPlayer: false,
    showMusicButton: false,
    list : [],
    current: null,
    switchedOnPlayer : true
};

const state = { ...initialState};

const mutations = {
    setShowPlayer(state, value) {
        state.showPlayer = value;
    },
    setShowMusicButton(state, value) {
        state.showMusicButton = value;
    },
    setList(state, list) {
        state.list = list;
    },
    clearList(state) {
        state.list.length = 0;
    },
    setTrackParam (state, {fileId, key, value}) {
        const track = state.list.find(x => x.fileId === fileId);
        if (track) {
            track[key] = value;
        }
    },
    setCurrent(state, current) {
        state.current = current;
    },
    setSwitchedOnPlayer(state, switchedOnPlayer) {
        state.switchedOnPlayer = switchedOnPlayer;
    },
};

const actions = {
    setShowPlayer({commit}, value){
        commit('setShowPlayer', value);
    },
    setShowMusicButton({commit}, value){
        commit('setShowMusicButton', value);
    },
    clearStorage({commit}){
        localStorage.removeItem("aplayer-setting");
    },
    init({rootState, commit, dispatch}){
        dispatch('clearStorage');
        const allElements = rootState.list.list.catalogColumns[0].elements;
        const elementsWithAudio = allElements
            .filter(x => x.audio_url != null && x.audio_url.length > 0);
        const list = [];
        for (let i = 0; i < elementsWithAudio.length; i++){
            list.push(
                new Track(
                    {
                        index : i,
                        fileId : elementsWithAudio[i].audio_url,
                        name: elementsWithAudio[i].audio,
                        artist: elementsWithAudio[i].performer,
                }));
        }
        commit('setList', list);
    },

    async play({dispatch, commit, state}, fileId){
        dispatch('setShowMusicButton', true);
        dispatch('setShowPlayer', true);
        const current = state.list.find(x => x.fileId === fileId);
        await dispatch('loadTrack', current);

        commit('setCurrent', fileId);
        dispatch('switchOnPlayer');

        if(state.list.length === 0) return;
        for (let i = 0; i < state.list.length; i++){
            await dispatch('loadTrack', state.list[i]);
        }
    },

    async loadTrack({commit}, track){
        if (track.loaded || track.failed) return ;
        await api.getAudio(track.fileId)
            .then(url => {
                commit('setTrackParam', {fileId : track.fileId, key : 'url', value : url});
                commit('setTrackParam', {fileId : track.fileId, key : 'loaded', value : true});
            })
            .catch(() => {
                commit('setTrackParam', {fileId : track.fileId, key : 'failed', value : true});
            })
            // .then(url => {
            //     const xhr = new XMLHttpRequest();
            //     xhr.open('GET', url, true);
            //     xhr.responseType = 'blob';
            //     xhr.onload = function() {
            //         if (this.status === 200) {
            //             const myBlob = this.response;
            //             new jsmediatags.Reader(myBlob)
            //                 .read({
            //                     onSuccess: function(tag) {
            //                         track.url = url;
            //                         track.defineName(tag.tags.title);
            //                         track.defineArtist(tag.tags.artist);
            //                         track.loaded = true;
            //                         // commit('setTrack', { track});
            //                     },
            //                     onError: function(error) {
            //                         track.url = url;
            //                         track.defineName(null);
            //                         track.defineArtist(null);
            //                         track.loaded = true;
            //                         // commit('setTrack', {track});
            //                     }
            //                 });
            //         }
            //     };
            //     xhr.send();
            // })
            // .catch(() => {})
    },

    switchInStore({commit}, fileId){
        commit('setCurrent', fileId);
    },

    switchOnPlayer({commit, state, getters}){
        commit('setSwitchedOnPlayer', false);

        function switchFunction() {
            setTimeout(function () {
                if (state.switchedOnPlayer){
                    return;
                }

                const track = state.list.find(x => x.fileId === state.current);
                const index = getters.loadedList.indexOf(track);

                if (index !== -1) {
                    EventBus.$emit('switch-on-player', index);
                    commit('setSwitchedOnPlayer', true);
                }
            }, 1000)
        }
        switchFunction();
    }
};



const getters = {
    currentisFetching: state => {
        if (state.current == null) return false;
        const c = state.list.find(x => x.fileId === state.current);
        return !!c && c.loaded;
    },
    loadedList: state => {
        return  state.list.filter(x => !x.failed && x.loaded);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
