import api from "../api/api";
import store from '../store/index';
import EventBus from "../event-bus";

const findList = (lists, uid) => {
    let result = null;
    lists.forEach(list => {
        if (list.uid === uid) {
            result = list;
        } else if (list.elements) {
            const innerSearch = findList(list.elements, uid);
            if (innerSearch != null) {
                result = innerSearch;
            }
        }
    });

    return result;
};

const initialState = {
    lists: [],
    sharedLists: [],
    showSharedLists: false,
    friends: [],
    notifications: [],
    unreadNotificationCount: 0,
    showSpinner: false,
    groupTitle: ''
};

const state = {...initialState};

const mutations = {
    addList(state, list) {
        state.lists.push(list)
    },
    setLists(state, lists) {
        state.lists = lists
    },
    clearLists(state) {
        state.lists.length = 0;
    },
    addElementsToList(state, {uid, elements}) {
        const list = findList(state.lists, uid);
        if (!list) return;
        list.isFolder = true;
        elements.forEach(el => {
            el.uid = crypto.randomUUID();
            if (el.childCatalog) {
                el.isFolder = true;
                el.elements = []
            }
        });
        list.elements = elements;
        list.elementsLoaded = true;
    },
    setSharedLists(state, sharedLists) {
        state.sharedLists = sharedLists
    },
    setShowSharedLists(state, value) {
        state.showSharedLists = value;
    },
    setFriends(state, friends) {
        state.friends = friends;
    },
    addNotifications(state, notifications) {
        if (state.notifications == null) {
            state.notifications = [];
        }
        state.notifications.push(...notifications);
    },
    markNotificationAsRead(state, notificationId) {
        state.notifications.forEach(n => {
            if (n.id === notificationId) {
                n.is_read = true;
            }
        })
    },
    markNotificationAsApply(state, notificationId) {
        state.notifications.forEach(n => {
            if (n.id === notificationId) {
                n.is_applied = true;
            }
        })
    },
    setUnreadNotificationCount(state, value) {
        state.unreadNotificationCount = value
    },
    setShowSpinner(state, showSpinner) {
        state.showSpinner = showSpinner
    },
    setGroupTitle(state, title) {
        state.groupTitle = title
    },
};

const actions = {
    setShowSharedLists({commit}, value) {
        commit('setShowSharedLists', value);
    },
    async loadSharedLists({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            api.getSharedListsOfUser()
                .then(data => {
                    if (data.length > 0) {
                        data.forEach(d => {
                            d.isFolder = true
                        });
                    }
                    commit('setSharedLists', data);
                    data.forEach(c => {
                        c.users
                            .filter(x => x.image)
                            .forEach(f => {
                                dispatch('avatar/addUser', {userId: f.image}, {root: true})
                            });
                    });
                    resolve(data);
                })
                .catch(status => reject(status));
        })
    },
    async loadTopLevelElements({commit, rootState}) {
        return new Promise(async (resolve, reject) => {
            commit('clearLists');
            let userName = null;
            let externalId = null;
            const userId = '_' + rootState.user.externalId;
            if (userId.startsWith('_')) {
                externalId = userId.substring(1);
            } else {
                userName = userId;
            }

            const data = await api.user.userGetListsOfUser({ token: store.getters.token, userName, externalId });

            data.forEach(list => {
                if (list.id !== -11) list.isFolder = true;
                list.uid = crypto.randomUUID();
                list.elements = [];
                commit('addList', list);
            });

            resolve(data);
        });
    },
    async loadUsersOfLists({commit, state}) {
        return new Promise((resolve, reject) => {

            state.lists.forEach(li => {
                api.getCatalogUsers(li.id, 5)
                    .then(data => {
                        li.users = data;
                        resolve();
                    })
                    .catch(error => {
                        reject()
                    })
            });
        });
    },
    async loadUsersOfManyLists({commit, state}) {
        return new Promise((resolve, reject) => {
            const catalogIds = state.lists.map(x => x.id);
            api.getCatalogsUsers(catalogIds, 5)
                .then(data => {
                    data.forEach(c => {
                        const list = state.lists.find(x => x.id === c.catalog_id);
                        if (list.users == null) list.users = [];
                        list.users.push(...c.users);
                    });
                    resolve();
                })
                .catch(error => {
                    reject()
                })

        });
    },
    async loadUsersOfSharedLists({dispatch, state}) {
        return new Promise((resolve, reject) => {
            state.sharedLists.forEach(li => {
                api.getCatalogUsers(li.id, 5)
                    .then(data => {
                        li.users = data;
                        li.users.forEach(u => {
                            dispatch('avatar/addUser', {userId: u.external_id}, {root: true});
                        });
                        EventBus.$emit('REFRESH_AVATARS_CATALOGS');
                        dispatch('avatar/tryToUpload', {}, {root: true});
                        resolve();
                    })
                    .catch(error => {
                        reject()
                    })
            });
        });
    },

    async loadSecondLevelElements({commit}) {
        return new Promise((resolve, reject) => {
            state.lists.forEach(list => {
                if (list.id !== -11) {
                    api.getElementsOfList(list.id)
                        .then(elements => {
                            const onlyFolders = elements.filter(x => !!x.childCatalog);
                            commit('addElementsToList', {elements: onlyFolders, uid: list.uid});
                            resolve(elements);
                        });
                }
            });
        })
    },

    async loadCurrentLevelElements({commit, dispatch}, list) {
        return new Promise((resolve, reject) => {
            if (list == null) return;
            if (list.elementsLoaded) return;
            const id = list.childCatalog.id;
            api.getElementsOfList(id)
                .then(elements => {
                    const onlyFolders = elements.filter(x => !!x.childCatalog);
                    commit('addElementsToList', {elements: onlyFolders, uid: list.uid});
                    resolve(elements);
                });
        })
    },

    changeListOrder({commit, rootState}, lists) {
        const cancellationCopy = JSON.parse(JSON.stringify(state.lists));
        commit('setLists', lists);
        const listIdList = lists.map(x => x.id);
        api.changeCatalogOrder(rootState.user.externalId, listIdList)
            .then(array => {
                // const lists = [];
                // for (let i = 0; i < array.length; i++){
                //     lists.push(ListHolder.createFrom(array[i], false));
                // }
            })
            .catch(status => {
                console.log(cancellationCopy);
                commit('setLists', cancellationCopy);
                if (status === 401) {
                    alert("Недостаточно прав");
                } else {
                    alert("Произошла ошибка");
                }
            });
    },
    async loadFriends({state, commit, dispatch}) {
        return new Promise((resolve, reject) => {
            api.getFriendsOfUser()
                .then(data => {
                    commit('setFriends', data);
                    state.friends.filter(x => x.image).forEach(friend => {
                        dispatch('avatar/addUser', {userId: friend.image}, {root: true});
                    });
                    resolve(data);
                })
                .catch(status => reject(status));
        })
    },

    async addFriend({commit}, id) {
        return new Promise((resolve, reject) => {
            api.addFriendOfUser(id)
                .then(() => {
                    resolve();
                })
                .catch(error => reject(error));
        })
    },

    async deleteFriend({commit}, id) {
        return new Promise((resolve, reject) => {
            api.deleteFriendOfUser(id)
                .then(() => {
                    resolve();
                })
                .catch(error => reject(error));
        })
    },


    async loadNotifications({state, commit}) {
        return new Promise((resolve, reject) => {
            const skip = state.notifications.length;
            const take = 20; //todo optimal for see by users
            api.getUserNotifications(skip, take)
                .then((data) => {
                    commit('addNotifications', data.notifications);
                    commit('setUnreadNotificationCount', data.unreadNotificationCount);
                    resolve();
                })
                .catch(error => reject(error));
        })
    },
    async getUnreadNotificationCountOfUser({commit}) {
        return new Promise((resolve, reject) => {
            api.getUnreadNotificationCountOfUser()
                .then((data) => {
                    commit('setUnreadNotificationCount', data);
                    resolve();
                })
                .catch(error => reject(error));
        })
    },
    async markNotificationAsRead({commit, dispatch}, notificationId) {
        return new Promise((resolve, reject) => {
            api.markNotificationAsRead(notificationId)
                .then((data) => {
                    commit('markNotificationAsRead', notificationId);
                    dispatch('getUnreadNotificationCountOfUser');
                    resolve();
                })
                .catch(error => reject(error));
        })
    },

    async markNotificationAsApply({commit, dispatch}, notificationId) {
        commit('markNotificationAsApply', notificationId);
        dispatch('getUnreadNotificationCountOfUser');
    },

    async applyFriendshipToNotification({commit}) {
        EventBus.$emit('APPLY_FRIENDSHIP_TO_NOTIFICATION');
    },
    async setShowSpinner({commit}, showSpinner) {
        commit('setShowSpinner', showSpinner);
    },
    setGroupTitle({commit}, title) {
        commit('setGroupTitle', title);
    }
};

const getters = {
    appliedFriends(state) {
        return state.friends.filter(x => x.i_agree);
    },
    friendRequests(state) {
        return state.friends.filter(x => !x.i_agree);
    },
    unreadNotifications(state) {
        return state.notifications.filter(x => (x.need_to_apply && !x.is_applied) || !x.is_read);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
